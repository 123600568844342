@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.work[data-v-7a34e354] {
  margin-left: -0.08rem;
}
.work li[data-v-7a34e354] {
    float: left;
    line-height: 0.26rem;
    margin: 0.12rem 0 0 0.08rem;
    cursor: pointer;
}
.work li div[data-v-7a34e354] {
      padding: 0.05rem 0.16rem;
      background: #F0F2F5;
      border-radius: 0.04rem;
}
.no-data[data-v-7a34e354] {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
