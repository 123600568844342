@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.home-custom[data-v-07e274ad] {
  border-radius: 0.04rem;
  background: #fff;
  box-shadow: 0 0.04rem 0.16rem rgba(122, 140, 153, 0.08);
  height: 100%;
  padding: 0 0 0.1rem 0;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  display: -moz-flex;
  display: -ms-flexbox;
  -moz-flex-direction: column;
  -o-flex-direction: column;
}
.home-custom .custom-title[data-v-07e274ad] {
    padding: 0 0.24rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    display: -moz-flex;
    display: -ms-flexbox;
    -moz-flex-direction: row;
    -o-flex-direction: row;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    -o-justify-content: space-between;
    overflow: hidden;
    flex-shrink: 0;
    height: 0.56rem;
    line-height: 0.56rem;
}
.home-custom .custom-title .title[data-v-07e274ad] {
      font-size: 0.16rem;
      font-weight: 500;
      color: #1b1d1f;
}
.home-custom .custom-title .more[data-v-07e274ad] {
      color: #869099;
      line-height: 0.56rem;
}
.home-custom .custom-main[data-v-07e274ad] {
    flex: 1;
    padding: 0 0.24rem;
    overflow: hidden;
    flex-shrink: 0;
    overflow: hidden;
}
