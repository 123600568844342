@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.notice-data .notice-li[data-v-1dc5ee46] {
  position: relative;
  cursor: pointer;
  padding-right: 1.7rem;
  line-height: 0.24rem;
}
.notice-data .notice-li[data-v-1dc5ee46]:hover {
    color: #488aff;
}
.notice-data .notice-li:hover .title[data-v-1dc5ee46] {
      position: relative;
      color: #488aff;
}
.notice-data .notice-li .title[data-v-1dc5ee46] {
    width: 100%;
    padding-top: 0.1rem;
    padding-right: 0.1rem;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #50565c;
}
.notice-data .notice-li .title span[data-v-1dc5ee46], .notice-data .notice-li .title img[data-v-1dc5ee46] {
      float: left;
}
.notice-data .notice-li .title img[data-v-1dc5ee46] {
      width: 0.38rem;
}
.notice-data .notice-li .notice-right[data-v-1dc5ee46] {
    position: absolute;
    right: 0;
    top: 0;
    color: #aab7c2;
}
.notice-data .notice-li .notice-right span[data-v-1dc5ee46] {
      margin-left: 0.12rem;
}
.notice-data .notice-li .notice-right span[data-v-1dc5ee46]:hover {
        color: #488aff;
}
.notice-data .icon_new[data-v-1dc5ee46] {
  position: absolute;
  right: -0.46rem;
  top: 0;
  background: red;
  font-size: 0.1rem;
  height: 0.16rem;
  border-radius: 0.1rem 0.08rem 0.08rem 0;
  color: #fff;
  line-height: 0.16rem;
  padding: 0 0.06rem;
  transform: scale(0.8);
}
.no-data[data-v-1dc5ee46] {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #666;
}
.detail-dialog[data-v-1dc5ee46] {
  font-size: 0.16rem;
  color: #444;
  text-indent: 2em;
}
.detail-dialog .detail-title[data-v-1dc5ee46] {
    font-size: 0.2rem;
    font-weight: bold;
    text-align: center;
    color: #488aff;
    padding-bottom: 0.16rem;
}
.detail-dialog .detail-content[data-v-1dc5ee46] p {
    margin-bottom: 0.04rem !important;
}
