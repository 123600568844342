
.icon_new{
    transition: all 1s;
    animation: jump .5s ease-in-out infinite alternate;
}
@keyframes jump {
from { top: -0.02rem;
}
to { top: 0.06rem
}
}
