@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.task-time-modular[data-v-0ed30c10] {
  display: flex;
  justify-content: space-between;
  margin: 0.16rem 0.86rem 0;
}
.task-time-modular .task-li .task-title[data-v-0ed30c10] {
    color: #A8ADBB;
}
.task-time-modular .task-li .task-value[data-v-0ed30c10] {
    margin-top: 0.16rem;
    color: #010511;
    font-size: 0.24rem;
}
.task-time-modular .task-li .task-scale[data-v-0ed30c10] {
    color: #010511;
    font-size: 0.12rem;
}
.task-time-modular .task-li .task-echar[data-v-0ed30c10] {
    margin-top: 0.1rem;
}
